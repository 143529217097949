<template>
  <div class="healthys">
    <div class="healthy">
      <van-tabs v-model="active">
        <van-tab title="电子档案">
          <!-- 基础档案 -->
          <div>
            <div class="block">
              <div class="title">基础信息</div>
              <div>
                <van-cell-group>
                  <van-cell title="姓名"
                    value="陈桥霞" />
                  <van-cell title="性别"
                    value="女" />
                  <van-cell title="出生日期"
                    value="1984-07-21" />
                  <van-cell title="身份证号"
                    value="410323198407210030" />
                  <van-cell title="本人电话"
                    value="18437987560" />
                  <van-cell title="联系人姓名"
                    value="陈靖远" />
                  <van-cell title="联系人电话"
                    value="13525496842" />
                  <van-cell title="民族"
                    value="汉族" />
                  <van-cell title="血型"
                    value="A型" />
                  <van-cell title="文化程度"
                    value="大专" />
                  <van-cell title="婚姻状况"
                    value="已婚" />
                  <van-cell title="药物过敏史"
                    value="无" />
                </van-cell-group>
              </div>
            </div>
            <div class="block">
              <div class="title">既往史</div>
              <div>
                <van-cell-group>
                  <van-cell title="疾病"
                    value="无" />
                  <van-cell title="手术"
                    value="无" />
                  <van-cell title="外伤"
                    value="无" />
                  <van-cell title="输血"
                    value="无" />
                </van-cell-group>
              </div>
            </div>
            <div class="block">
              <div class="title">家族史</div>
              <div>
                <van-cell-group>
                  <van-cell title="配偶"
                    value="无" />
                  <van-cell title="子女"
                    value="无" />
                  <van-cell title="其他"
                    value="无" />
                </van-cell-group>
              </div>
            </div>
            <div class="block">
              <div class="title">遗传病史</div>
              <div>
                <van-cell-group>
                  <van-cell title="遗传病"
                    value="无" />
                </van-cell-group>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="体检报告">
          <!-- 体检记录 -->
          <div>
            <div class="block">
              <div class="title">内科</div>
              <div>
                <van-cell-group>
                  <van-cell title="血压"
                    value="90mmhg" />
                  <van-cell title="脉搏"
                    value="100" />
                  <van-cell title="呼吸"
                    value="60次/分" />
                  <van-cell title="心率"
                    value="80次/分" />
                  <van-cell title="心肺功能"
                    value="" />
                  <van-cell title="淋巴"
                    value="" />
                </van-cell-group>
              </div>
            </div>
            <div class="block">
              <div class="title">外科</div>
              <div>
                <van-cell-group>
                  <van-cell title="头部"
                    value="正常" />
                  <van-cell title="四肢"
                    value="正常" />
                  <van-cell title="胸部"
                    value="正常" />
                  <van-cell title="腹部"
                    value="正常" />
                  <van-cell title="脊椎"
                    value="正常" />
                </van-cell-group>
              </div>
            </div>
            <div class="block">
              <div class="title">功能化验</div>
              <div>
                <van-cell-group>
                  <van-cell title="心电图"
                    value="正常" />
                  <van-cell title=" 胸 透 "
                    value="正常" />
                  <van-cell title=" 血 糖 "
                    value="正常" />
                </van-cell-group>
              </div>
            </div>
            <div class="block">
              <div class="title">体检结论</div>
              <div>
                <div style="font-size: 15px; margin: 15px">各项指标正常</div>
                <van-cell-group>
                  <van-cell title="主检医师"
                    value="王毅" />
                  <van-cell title="体检时间"
                    value="2021-06-30 08:30" />
                </van-cell-group>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <!-- 上传老人健康信息 -->
    <van-cell-group v-if="Healthyshow">
      <van-field v-model="Healthy.GroupName"
        @click="Groupshow = true"
        readonly="readonly"
        label="录入信息分类"
        placeholder="请选择录入信息分类" />
      <van-popup v-model="Groupshow"
        position="bottom"
        get-container="body">
        <van-picker show-toolbar
          :columns="groupList"
          @cancel="Groupshow = false"
          @confirm="onGroupshow">
        </van-picker>
      </van-popup>
      <van-field v-model="Healthy.HighPressure"
        v-if="Healthy.Group == 1"
        label="血压(高压)"
        placeholder="请输入测量血压" />
      <van-field v-model="Healthy.LowPressure"
        v-if="Healthy.Group == 1"
        label="血压(低压)"
        placeholder="请输入测量血压" />
      <van-field v-model="Healthy.HeartRate"
        v-if="Healthy.Group == 3"
        label="平均心率(分)"
        placeholder="请输入平均心率" />
      <van-field v-model="Healthy.BloodSugar"
        v-if="Healthy.Group == 4"
        label="血糖(mmol/l)"
        placeholder="请输入血糖值" />
      <van-field v-model="Healthy.Weight"
        v-if="Healthy.Group == 5"
        label="体重(KG)"
        placeholder="请输入体重" />
      <van-field v-model="Healthy.Temperature"
        v-if="Healthy.Group == 2"
        label="体温(℃)"
        placeholder="请输入体温" />
    </van-cell-group>
    <van-button v-if="Healthyshow"
      class="fromon"
      round
      type="info"
      @click="SaveHealthy()">提 交</van-button>
    <!-- 微信录入老人体检报告 -->
    <van-cell-group v-if="Physicalsshow">
      <van-field v-model="Physicals.PName"
        label="体检机构名称"
        placeholder="请输入体检机构名称" />
      <van-field v-model="Physicals.PTime"
        @click="PTimeshow = true"
        readonly="readonly"
        label="体检时间"
        placeholder="请选择体检时间" />
      <van-popup v-model="PTimeshow"
        position="bottom"
        get-container="body">
        <van-datetime-picker show-toolbar
          type="date"
          title="选择出生日期"
          :min-date="minDate"
          :max-date="maxDate"
          v-model="currentDate"
          @cancel="PTimeshow = false"
          @confirm="onPTimeshow">
        </van-datetime-picker>
      </van-popup>
      <van-field v-model="Physicals.Remark"
        rows="1"
        autosize
        label="体检情况说明"
        type="textarea"
        placeholder="请输入体检情况说明" />
      <div class="spanfield">体检图片</div>
      <van-uploader v-model="fileList"
        multiple
        class="imgs"
        :max-count="9"
        :after-read="afterRead"
        :deletable="true"
        @delete="shanchu" />
    </van-cell-group>
    <van-button class="fromon"
      v-if="Physicalsshow"
      round
      type="info"
      @click="SavePhysicals()">提 交</van-button>
  </div>
</template>
<script>
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
import {
  WePageHealthyList,
  WeSaveHealthy,
  WeDeleteHealthy,
  WePagePhysicalsList,
  WeSavePhysicals,
  WeDeletePhysicals,
} from "@/api/Pension";
import config from "@/config";
import axios from "axios";
import { setOpenId, getOpenId } from "@/utils/auth";
export default {
  data () {
    return {
      fileList: [],
      groupList: [
        { ID: 1, text: "血压" },
        { ID: 2, text: "体温" },
        { ID: 3, text: "心率" },
        { ID: 4, text: "血糖" },
        { ID: 5, text: "体重" },
      ],
      Physicals: {
        //体检信息
        PId: 0, //体检id,
        PName: "", //体检所在机构名称,
        PTime: "", //体检时间,
        EId: 0, //老人信息id,
        OpenID: getOpenId(), //提交微信openid,
        Remark: "", //体检情况说明,
        Imgs: "", //体检报告图片-多图
      },
      Healthy: {
        //健康信息
        HId: 0, //老人id、
        OpenID: getOpenId(), //微信用户openid,
        HighPressure: "", //血压-高压,
        LowPressure: "", //血压-低压,
        HeartRate: "", //平均心率,
        BloodSugar: "", //血糖值,
        Weight: "", //体重kg,
        Temperature: "", //体温,
        Group: "", //录入信息分类（1、血压、2-体温、3-心率、4-血糖）
      },
      uploadImgs: [],
      minDate: new Date(1970, 0),
      maxDate: new Date(),
      currentDate: new Date(),
      Healthyshow: false, //健康信息
      Physicalsshow: false, //体检信息
      PTimeshow: false, //时间
      Groupshow: false, //信息分类
    };
  },
  created () {
    this.PhysicalsList();
    this.HealthyList();
  },
  methods: {
    // 图片上传
    afterRead (file) {
      let c = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
      formData.append("file", file.file);
      // 添加请求头
      axios.post(config.apiUploadUrl, formData, c).then((res) => {
        if (res.data.code === 0) {
          var arr = this.uploadImgs;
          arr.push(res.data.data);
          this.uploadImgs = arr;
        }
      });
    },
    // 图片删除
    shanchu: function () { },
    // 获取老人记录
    PhysicalsList: function () {
      WePagePhysicalsList({ eId: 0, page: 1, limit: 10 }).then((res) => {
        console.log(res);
      });
    },
    HealthyList: function () {
      WePageHealthyList({ hId: 0, page: 1, limit: 10 }).then((res) => {
        console.log(res);
      });
    },
    // 录入体检记录
    SavePhysicals: function () {
      this.Physicals.Imgs = this.uploadImgs.toString();
      WeSavePhysicals(this.Physicals).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("提交成功！");
        } else {
          Toast.fail("提交失败，" + res.data.msg);
        }
      });
    },
    // 录入健康信息
    SaveHealthy: function () {
      WeSaveHealthy(this.Healthy).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("提交成功！");
        } else {
          Toast.fail("提交失败，" + res.data.msg);
        }
      });
    },
    // 时间选择器
    onPTimeshow (val) {
      //工作经历 入职时间 确认
      this.Physicals.PTime = this.dateformat(val);
      this.PTimeshow = false;
    },
    //分类选择器
    onGroupshow (val) {
      this.Healthy.Group = val.ID;
      this.Healthy.GroupName = val.text;
      this.Groupshow = false;
    },
    dateformat (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style>
.healthys {
  background: #ededed;
}
.block {
  width: 92%;
  background: #fff;
  margin: 15px 4%;
}
.block .title {
  text-align: center;
  padding: 8px 0px;
}
.healthy .van-cell {
  height: 35px;
  line-height: 14px;
}
.fromon {
  width: 90%;
  margin: 15px 5%;
}
.spanfield {
  /* position: relative;
  top: -10px; */
  font-size: 14px;
  margin: 10px 30px;
  color: #646566;
}
.imgs {
  margin-top: 10px;
  margin-left: 15px;
}
.healthy .van-cell__title {
  text-align-last: justify;
  flex: 0.3;
}
</style>